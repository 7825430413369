.checkpoint {
    background-color: white;
    border: 2px solid #334E58;
    border-radius: 0.5rem;
    margin: 2rem 0;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    position: relative;

    label {
      background-color: #334E58;
      color: white;
      border-radius: 0 0 0.5rem 0;
      font-size: 0.75rem;
      font-weight: bold;
      padding: 0.25rem 0.5rem;
      position: absolute;
      top: 0;
    }

    .content {
      margin-top: 1.5rem;
      padding: 1rem;
    }
  }

  .courseTextChallenge {
    background-color: white;
    border: 2px solid purple;
    border-radius: 0.5rem;
    margin: 2rem 0;
    position: relative;

    label {
      background-color: purple;
      color: white;
      border-radius: 0 0 0.5rem 0;
      font-size: 0.75rem;
      font-weight: bold;
      padding: 0.25rem 0.5rem;
      position: absolute;
      top: 0;
    }

    .content {
      margin-top: 1.5rem;
      padding: 1rem;
    }
  }

  .codeExerciseChallenge {
    background-color: white;
    border: 2px solid purple;
    border-radius: 0.5rem;
    margin: 2rem 0;
    position: relative;

    label {
      background-color: purple;
      color: white;
      border-radius: 0 0 0.5rem 0;
      font-size: 0.75rem;
      font-weight: bold;
      padding: 0.25rem 0.5rem;
      position: absolute;
      top: 0;
    }

    .content {
      margin-top: 1.5rem;
      padding: 1rem;
    }
  }

  .customExtensionStyle {
    background-color: white;
    border: 2px solid purple;
    border-radius: 0.5rem;
    margin: 2rem 0;
    position: relative;

    label {
      background-color: purple;
      color: white;
      border-radius: 0 0 0.5rem 0;
      font-size: 0.75rem;
      font-weight: bold;
      padding: 0.25rem 0.5rem;
      position: absolute;
      top: 0;
    }

    .content {
      margin-top: 1.5rem;
      padding: 1rem;
    }
  }