.center-text {
    display: flex;
    justify-content: center;
    align-items: center;
}

.prevent-text-selection {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.game-button {
    display: inline-block;
    margin: 10px;
    padding: 5px;
    width: 100px;
}

.game-button-green {
    display: inline-block;
    margin: 10px;
    padding: 5px;
    width: 150px;
    background-color: lightgreen;
}

.game-button-red {
    display: inline-block;
    margin: 10px;
    padding: 5px;
    width: 100px;
    background-color: lightcoral;
    /* user-select: none; */
}
