.ProseMirror {
    padding-inline: 4rem;
  
    > * + * {
      margin-top: 0.75em;
    }
  
    [data-id] {
      border: 3px solid #0D0D0D;
      border-radius: 0.5rem;
      margin: 1rem 0;
      position: relative;
      margin-top: 1.5rem;
      padding: 2rem 1rem 1rem;
  
      &::before {
        content: attr(data-id);
        background-color: #0D0D0D;
        font-size: 0.6rem;
        letter-spacing: 1px;
        font-weight: bold;
        text-transform: uppercase;
        color: #fff;
        position: absolute;
        top: 0;
        padding: 0.25rem 0.75rem;
        border-radius: 0 0 0.5rem 0.5rem;
      }
    }
  }